import React from "react";
import "./UnderConstruction.css";
import underConstructionImage from "./assets/under-construction.png";

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <img
        className="construction-image"
        src={underConstructionImage}
        alt="Under Construction"
      />
      <h1>Under Construction</h1>
      <p>
        We're working hard to bring you an amazing website. Please check back
        soon!
      </p>
    </div>
  );
};

export default UnderConstruction;
