import React from "react";
import "./Main.css";
import UnderConstruction from "./UnderConstruction";

const Main = () => {
  const isUnderConstruction = true; // Set this to false when your pages are ready

  return (
    <div className="main-container">
      {isUnderConstruction ? (
        <UnderConstruction />
      ) : (
        <>{/* Your actual page content goes here */}</>
      )}
    </div>
  );
};

export default Main;
